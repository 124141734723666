@font-face {
    font-family: 'DMSans-Regular';
    src: url('./Fonts/DMSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'DMSans-Medium';
    src: url('./Fonts/DMSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'DMSans-Bold';
    src: url('./Fonts/DMSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'DMSans-Italic';
    src: url('./Fonts/DMSans-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'DMSans-BoldItalic';
    src: url('./Fonts//DMSans-BoldItalic.ttf') format('truetype');
}

* {
    font-family: 'DMSans-Regular';
}